.logo-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -25px;
    justify-content: space-between;
    /*margin: 0 -26px*/
     
         
           
}

.logo-list__img {
    /*margin: 30px 35px;
    height: 90px;*/
    // margin: 20px 0; 
    max-height: 80px;
  
 
}

.logo-list__item {
    flex: 1 1 25%;

    @media @mobile {
        flex: 1 1 ~"calc(50% - 20px)";
        margin: 0 10px;
    }
}
.logo-list__img-container{
    min-height: 100px;
}
.logo-list__title {
    color: @c-blue;
    margin-bottom: 15px;
    font-weight: 600; 
    .font-size(20);
}

.logo-list--text{
    margin: 0;
    .font-size(16);
    align-items: unset;

    .logo-list__img-container{
        min-height: 150px;
        display: flex;
        align-items: center; 
        margin-bottom: 20px;
    }

    @media @mobile{
        margin: 0 -10px;
    }
}

.icon-flex{
    // flex: 1 1 13%;
    margin: 0 25px 50px;
    &:last-child {
        margin-right: auto;
    }
}