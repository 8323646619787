.events__container
{
   
}
.events__list {
   
    padding-left:0;
}
.events__list-item {
   margin:15px 0;

}
.events__list-title{
    font-weight:500;
}