input, select, textarea {
    appearance: none;
    border-radius: 0;
}

.contact-form__container {
    max-width: @container-width--small;
}

.umbraco-forms-fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

.umbraco-forms-form {
    .col-md-12, .container {
        padding: 0;
    }
}

.umbraco-forms-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    h2 {
        .font(@font-size: 50, @line-height: 55, @letter-spacing: 0);
        margin-bottom: 30px;
        font-weight: 300;
    }
    p {
        .font(@font-size: 22, @line-height: 31, @letter-spacing: 0);
        margin-bottom: 30px;
        font-weight: 300;
    }

}

.umbraco-forms-navigation {
    display: flex;
    margin: 30px 0 0;

    @media @tablet-min {
        &:before {
            content: '';
            background-image: url("/img/ContactArrows.svg");
            display: block;
            width: 655px;
            height: 47px;
            left: 5px;
            position: relative;
            margin-right: 40px;
            flex: 1;
        }
    }

    .campaignPage & {
        &:before{
            display:none;
        }
    }

    .col-md-12 {
        flex-basis: 0;
    }
}

.umbraco-forms-field {
    display: block;
    margin: 0 4px 8px;
    flex: 1 1 48%;

    @media @mobile {
        margin: 0 0 8px;
        flex: 1 0 100%;
    }

    .campaignPage & {
        margin: 0 0 8px;
        flex: 1 0 100%;
    }

    &.titleanddescription {
        flex: 0 1 100%;
    }

    &.longanswer {
        flex: 0 1 100%;
    }

    &.dataconsent {
        text-align: left;
    }

    &.dropdown {
        position: relative;

        &:after {
            content: '';
            width: 1.25rem;
            height: 1.25rem;
            background-image: url(/img/dropdown-arrow.svg);
            opacity: .4;
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            position: absolute;
            right: 0.9375rem;
            top: 1.45rem;
            pointer-events: none;
        }
    }

    &.fileupload {
        flex: 0 1 100%;

        label {
            display: block;
            position: relative;
            height: 50px;

            &:before {
                content: 'Click here to upload CV';
                width: 100%;
                background-color: #FFF;
                background-image: url(/img/upload.svg);
                background-repeat: no-repeat;
                background-size: 17px 18px;
                background-position: right 30px center;
                height: 50px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                color: @c-dark;
                .font(@font-size: 17, @line-height: 17, @letter-spacing: 0);
                text-align: left;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .transition(.3s);
            }

            &:hover {
                &:before {
                    background-color: darken(#eee, 8%);
                }
            }
        }

        input[type=file] {
            display: none;
        }
    }
}


.campaignPage .umbraco-forms-field {
    margin: 0 0 8px;
    flex: 1 0 100%;

    &.shortanswer {
        border: 1px solid #EAEAEA;
    }

    &.longanswer {
        border: 1px solid #EAEAEA;
    }

    .radio-container label:before {
        background-color: #b1acac;
    }

    .umbraco-forms-navigation:before {
        display: none;
    }

    input [type=submit] {
        color: inherit;
        border: 1px solid #EAEAEA;
    }
}

input:not([type=radio]), select {
    width: 100%;
    height: 50px;
    padding: 20px;
    border: 0;
    appearance: none;
    font-family: @font-sans-serif;
    font-size: 1.0625rem;
    font-weight: 300;
    &:focus {
        outline: none;
    }
}

select {
    background-color: #FFF;
    border: 0;
    padding: 0 20px;
}

input[type=radio] {
    display: inline-block;
}

input[type=submit] {
    width: 125px;
    height: 42px;
    padding: 0;
    background: none;
    color: #fff;
    box-shadow: none;
    outline: none;
    border: solid 1px #fff;
    font-weight: 600;
    // margin-top: 30px;
    .transition(.3s);

    &:hover {
        cursor: pointer;
        background-color: #FFF;
        color: @c-blue;
    }

    .campaignPage & {
        color: inherit;
        border: 1px solid #EAEAEA;
        margin: 0 100%;
    }
}

textarea {
    width: 100%;
    height: 117px;
    font-family: @font-sans-serif;
    font-size: 1.0625rem;
    font-weight: 300;
    padding: 20px;
    border: 0;
    &:focus {
        outline: none;
    }
}

.umbraco-forms-field.dataconsent {
    margin-top: 10px;

    label {
        

        @media @desktop-wide {
       padding-top: 2px;
        }

        @media @laptop {
            padding-top:3px;
        }

        display: block;
        margin-bottom: 10px;
        font-weight: 500;
    }
}

.radio-container {
    padding-left: 30px;
    position: relative;
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
    input[type=radio] {
        display: none !important;
        &:checked {
            &+label {
                &:before {
                    background-color: @c-secondary;
                }
            }
        }
    }
    label {
        margin: 0 !important;
        cursor: pointer;
        font-weight: 300 !important;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 15px;
            top: 4px;
            height: 15px;
            background-color: #FFF;
            // border: 1px solid #414042;
            border-radius: 50%;
            .transition(.2s);
        }
    }
}

select {
    width: 100%;
    height: 50px;
    color: rgb(107, 107, 107);
}

label {
    display: none;
}

input[type=file]
{
    width: 100%;

}

.field-validation-error {
    display: block;
    color: #8a1f11 !important;
}

.input-validation-error {
    background-color: #FBE3E4 !important;
}

.apply-form{
    text-align: center;
}

.apply-form__container {
    max-width: 860px;
    margin: 0 auto;
}

.careers-panel{
    .apply-form__container{
        margin-top: 100px;
    }
}
.email-link {
    color: #fff;
    text-decoration: none;
    font-weight: 600;

    &--rte {
        color: @c-body;
        font-weight: 500;
        text-decoration: none;
    }
}

.umbraco-forms-submitmessage {
    &:before {
        content: '';
        background-image: url(/img/blue-arrow.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 25px;
        height: 25px;
        display: block;
        margin: 0 auto 10px;
    }
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
}

option{
    font-weight: 300;
}
.file-upload__title{
    display: block !important;
}
.file-upload__confirm{
    display: flex;
    margin-top: 10px;
    justify-content: center;
   
}

.recaptcha {
    display: none;
}