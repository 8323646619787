
.nested-panel {

    &--countdown {
        background-color: #002944;
        font-family: "LonginesSerifText";
        position: absolute;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding-top: 15rem !important;
        /*&:before {
            content: "";
            position: absolute;
            width: 200%;
            height: 200%;
            top: -50%;
            left: -50%;
            z-index: -1;
            background: url(/img/Countdown/background.jpg) 0 0 no-repeat;
            animation: rotation 50s infinite;
        }*/
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 75px;
            min-height: 130px;
            background-image: url(/img/CaseStudyArrowsWhite.svg);
            background-repeat: no-repeat;
            max-width: 1530px;
            width: 100%;
            z-index: 10;

            @media @desktop-wide {
                left: @layout-padding;
                right: @layout-padding;
                width: auto;
                // width: 100%;
                transform: none;
            }

            @media @mobile {
                min-height: 85px;
            }
        }



        @font-face {
            font-family: "LonginesSerifText";
            src: url("/fonts/LonginesSerifText/LonginesSerifText-Regular.otf");
        }

        @font-face {
            font-family: "LonginesSansTexte";
            src: url("/fonts/LonginesSansText/LonginesSansText-Bold.otf");
        }



        img {
            //width: 100%;
            margin: 0 auto;
            max-width: 65%;

            @media @mobile {
                max-width: 100%;
            }
        }

        #container {
            font-family: "LonginesSerifText";
            overflow: visible;
            color: #fff;
            // margin: auto;
            width: 100%;
            text-align: center;
            z-index: 11;
        }

        #days, #hours, #minutes, #seconds {
            font-family: "LonginesSansTexte";
            overflow: visible;
            color: #fff;
            margin: auto;
            text-align: center;
        }


        ul {
            padding-left: 0;
        }

        li {
            display: inline-block;
            font-size: 1.4em;
            list-style-type: none;
            padding: 1em;
            text-transform: uppercase;

       
        }

        li span {
            display: block;
            font-size: 4.5rem;
        }

        @media all and (max-width: 940px) {

            li {
                font-size: 1.125rem;
                padding: .75rem;
            }

            li span {
                font-size: 3.375rem;
            }
        }


        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }

        li{
            @media @mobile{
                padding: .45rem;
            }
        }
    }
}
.countdown-row__text-content {
    text-align: left;
    margin-bottom: 60px;
    font-family: @font-sans-serif;
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
    font-weight: 300;
   // margin-bottom: 1.875rem;

    @media @desktop {
        font-size: 1.4rem;
      //  display: none;
    }

    @media @tablet {
        font-size: 1.2rem;
    }

    p {
        font-size: inherit;
        font-weight: inherit;
    }

    @media @laptop {
        margin-top: 40px;
    }

    @media @mobile {
        margin-top: 0;
    }
}
.countdown-row__countdown{
    margin: 0 auto;
    max-width: 1053px;

    
    @media @tablet{
        padding: 0 25px;
    }
}
.countdown-row__title {
    font-weight: 500;
    .font-size(50);
    .letter-spacing(-0.75);
    margin-bottom: 30px;

    @media @mobile{
        font-size: 30px;
    }
}

.countdown-row__overlay {
    background: red;
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(/img/countdown/background.jpg) 0 0 no-repeat;
    animation: rotation 70s infinite;
    width: 200%;
    height: 200%;
}

