.cta-panel {
    background-color: rgb(243, 243, 243);
    padding: 30px;
    @media @mobile {
        padding: 30px;
    }
}

.cta-panel__title {
    font-size: 2rem;
}

.cta-panel__buttons {
    margin-bottom: -10px;
}