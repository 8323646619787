.filter-row {
    border-bottom: 1px solid @c-body;
    margin: 0 0 30px 0;
    padding-bottom: 0.625rem;
    @media @tablet-min {
        display: flex;
    }
    @media @tablet {
        border: 0;
        padding: 0;
    }
    &--news {
        border-bottom: 1px solid #fff;
        @media @tablet{
            border: none;
        }
        .filter-row__filter {
            &:after {
                background-image: url(/img/dropdown-arrow--white.svg) !important;
            }
        }
    }
}

.filter-row__tag {
    font-weight: 700;
    margin-right: 2.0625rem;
    display: block;
    @media @tablet {
        margin: 0 0 1.25rem;
        border-bottom: 1px solid;
        padding-bottom: 0.625rem;
    }
}

.filter-row__filters {
    .transition(.5s);
    @media @tablet-min {
        display: flex;
    }
    @media @tablet {
        max-height: 0;
        overflow: hidden;
    }
    &--active {
        max-height: 1000px;
    }
}

.filter-row__filter {
    position: relative;
    margin-right: 2.0625rem;
    padding-right: 1.25rem;
    display: block;
    cursor: pointer;
    &:not(&--all) {
        &:after {
            content: '';
            width: 0.625rem;
            height: 1.375rem;
            background-image: url(/img/dropdown-arrow.svg);
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            position: absolute;
            right: 0;
            top: 0.5625rem;
            transform-origin: top;
            .transition(.3s);
            pointer-events: none;
        }
    }
    &--active {
        &:after {
            transform: scaleY(-1) translateY(-5px);
        }
    }
    @media @tablet-min {
        &:hover {
            &:after {
                transform: scaleY(-1) translateY(-5px);
            }
            .filter-row__dropdown {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: all;
            }
        }
    }
    @media @tablet {
        margin-bottom: 20px;
        padding-right: 0;
        margin-right: 0;

    }
}

.filter-row__title {
    @media @tablet {
        display: block;
        padding-right: 1.25rem;
    }
}

.filter-row__dropdown {
    @media @tablet-min {
        background-color: @c-main;
        color: #FFF;
        position: absolute;
        display: block;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        transform: translateY(-10px);
        top: 3.125rem;
        left: -1.25rem;
        box-shadow: 0 10px 20px -10px rgba(0,0,0,.5);
        min-width: 15.8125rem;
        z-index: 50;
        .transition(.3s);
        &:before, &:after {
            content: '';
            position: absolute;
        }
        &:before {
            top: -2.0625rem;
            right: 0;
            left: 0;
            height: 2.0625rem;
        }
        &:after {
            height: 0.9375rem;
            width: 0.9375rem;
            background-color: @c-main;
            transform: rotate(45deg);
            top: -0.3125rem;
            left: 1.25rem;
            z-index: -1;
        }
    }
    @media @tablet {
        border-left: 1px solid @c-body;
        margin-left: 0.625rem;
        padding-left: 0.625rem;
        max-height: 0;
        overflow: hidden;
        &--active {
            max-height: none;
            margin: 20px 0;
        }
    }

}

.filter-row__option, .filter-row__option--news {
    display: block;
    padding: 0.9375rem;
    border-bottom: 1px solid fade(#FFF, 40%);
    cursor: pointer;
    &:last-of-type {
        border-bottom: 0;
    }
    &--active {
        background-color: darken(@c-main,10%);
        @media @tablet {
            background: none;
            color: @c-main
        }
    }
}