.announcement {
    background-color: #f2f0f3;
    padding: 30px 30px 30px 30px;
    text-align: left;
    /*margin: 30px auto;*/
    position: relative;
    /*border-radius: 5px;*/
    overflow: hidden;
    /*.transition(.3s);*/
   margin-top: 5rem;
    @media @mobile {
        padding: 30px;
    }
    @media @mobile-s {
        padding: 20px;
    }
    /*&:before {
        content: '\f071';
        font-family: 'fontAwesome';
        position: absolute;
        top: 30px;
        left: 30px;
        font-size: 1.5rem;
        background-color: @c-blue;
        padding: 6px 10px 10px 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        text-indent: 2px;
        @media @mobile {
            position: relative;
            top: auto;
            left: auto;
            margin-bottom: 10px;
        }*/
    /*}*/
}

.announcement--hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    pointer-events: none;
   /*display:none;*/
}

.announcement__title {
    text-align: left;
    margin-bottom: 15px;
    line-height: 1;
    font-size: 1.8rem;
    font-weight: 400;
}

.announcement__content {
    max-width: 95%;
}

.announcement__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 50;
    font-size: 20px;
    font-weight: 700;
    will-change: transform;
    
    /*.transition;*/
    &:after {
        content: 'X';

    }
    &:hover {
        transform: scale(.9);
    }
}

.announcement__link {
    margin-top: 10px;
    display: inline-block;
    font-weight: 600;
}

.announcement-img {
    width: 200px;
    height: auto; 
    /*position: absolute;
    top: 30px;
    left: 30px;*/

    @media @mobile{
        display: block; 
        margin-bottom: 10px;
        position: unset; 
    }
}