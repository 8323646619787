.gateway-img {
    @media @desktop-min {
        margin-left: auto;
    }
}
.gateway__header-row {
    margin-bottom: 80px;
}

.gateway__content-row {
    @media @desktop-min {
        flex-direction: row-reverse;
    }
}

.col {
    @media @desktop {
        &--image {
            order: 1;
        }
        &--content {
            order: 3;
        }
        &--sidenav {
            order: 2;
            margin-bottom: 4.375rem;
            margin-top: -0.9375rem;
        }
        &--last {
            order: 4;
        }
    }
}
