.button {
    background-color: @c-main;
    color: #FFF;
    border: 0;
    padding: 10px 20px;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    display: inline-block;
    min-width: 120px;
    font-size: .75rem;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
    margin: 0 10px 10px 0;
    font-family: @font-sans-serif;
    .transition(.3s);

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        pointer-events: none;
        opacity: 0;
        .transition(.2s);
    }

    &:hover {
        text-decoration: none;
        color: #FFF;

        &:after {
            opacity: .1;
        }
    }

    &--secondary {
        background-color: @c-secondary;
    }

    &--ghost {
        background: none;
        border: 1px solid @c-main;
        color: @c-main;

        &:hover {
            background-color: @c-main;
        }
    }

    &--center {
        margin: 0 auto;
        display: block;
    }

    &--leadership {
        width: 120px;
        border: 1px solid @c-grey--dark;
        color: @c-grey--dark;
        margin-top: 50px;

        &:hover { 
            background-color: @c-grey--dark;
            color: #fff;
        }
    }
     
    &--feature {
        width: 120px;
        border: 1px solid #fff;
        color: #fff;
        margin-top: 40px;
        background-color: transparent; 
        // position: absolute; 
        // bottom: 0;
        margin-top: auto;
        @media @tablet{
            position:unset;
            margin-top: 20px;
        }

        /*&:hover {
            background-color: @c-main;
            color: #fff;
        }*/
    }

    &--rte {
        padding: 0; 

        a {
            padding: 10px 20px;
            color: inherit;
            text-decoration: none;
            display: block;
        }
    }
}
#loadMoreNews {
    background: none;
    color: #fff;
    border: solid 1px #fff;
    font-weight: 600;

    &:hover {
        color: #286EF0;
        background-color: #fff;
    }
}