.carousel {
    width: 100%;

    &--large {
        background-color: #eee;
        overflow: hidden;
        padding-top: 40%;
        position: relative;
        height: 1080px;
        min-height: 660px;
        max-height: 100vh;

        @media @tablet {
            padding-top: 66%;
        }

        @media @mobile {
            /*padding-top: 125%;*/
            height: 450px;
        }

        .carousel__holder {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &--portrait {
        height: 100%;
        background: none;
        padding-bottom: 80px;
        // @media @tablet {
        //     padding-top: 145%;
        // }

        .panel--carousel {
            // top: 20px;
            display: none !important;
        }
    }

    &__container {
        color: #fff;
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        //top: 0;
        top: 25%;
        left: 50%;
        transform: translateX(-50%);

        @media @mobile {
            top: 25%;
        }
    }

    &__slide {
        // background-color: #eee;
        /*max-height: 800px;*/
        height: 100%;

        img {
            width: 100%;
        }
    }

    &__img {
        object-fit: contain;
        margin: 0 auto;
        // max-height: 800px;

        @media @mobile {
            object-fit: cover;
            min-height: 660px
        }
    }

    &__content {
        position: absolute;
        bottom: 70px;
        background-color: rgba(0,0,0,.7);
        padding: 30px;
        color: #FFF;
        z-index: 10;

        a {
            color: inherit;
        }

        &-link {
            margin: 30px 0 0 11px;
        }
    }

    &__caption {
        @media @tablet-min {
            position: absolute;
            top: 26px;
            font-size: 1rem;
            margin: 0;
        }
    }

    .main-banner__content-container {
        flex: 1;
    }

    .main-banner__title {
        font-size: 5.6rem;

        @media @mobile {
            max-width: 345px;
        }
    }

    .button--ghost {
        border: 1px solid #fff;
        color: #fff;
    }
}

.splide {
    .splide__pagination {
        margin-left: -12rem;
        left: 50%;
        bottom: 33px;

        @media @tablet {
            left: 20px;
            margin-left: 0;
        }

        @media @mobile {
            margin: 0;
            left: 0;
            bottom: 0;
        }
    }
}

.splide__pagination {
    position: absolute;
    //  background-color: #FFF;
    padding: 15px;
    background: none;
    width: 300px;
    text-align: center;
    z-index: 2 !important;
    margin-left: -12rem;

    @media @desktop-wide {
        margin: 0;
        left: @layout-padding;
    }

    @media @mobile {
        width: auto;
        right: @layout-padding;
        background: none;
        bottom: 15px;
    }

    button {
        height: 4px;
        width: 40px;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 0;
        margin-right: 0;
        opacity: 1;
        z-index: 3;
        position: relative;
        margin-bottom: 14px;
        //outline: none;
        .transition(.2s);

        @media @mobile {
            background: none;
            border: 1px solid #FFF;
            height: 8px;
            width: 40px;
            opacity: 1;
        }
        /*  &:last-of-type {
            margin-right: 0;
        }*/

        /*&:hover {
            background-color: @c-main;
        }*/

        &.active {
            opacity: 1;
            background-color: @c-main;
            border: 1px solid @c-main;

            @media @mobile {
                background-color: @c-main;
                border: 2px solid #FFF;
            }
        }
    }
}

.splide__arrows {
    /*background: #fff;*/
    padding: 23px;
    position: absolute;
    bottom: 30px;
    width: 300px;
    left: 50%;
    margin-left: -12rem;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    .splide__arrow--prev {
        left: .5em;
        /*   @media @mobile {
            left: 1.5em;
        }*/
    }

    .splide__arrow--next {
        right: .5em;
        /*   @media @mobile {
            right: 1.5em;
        }*/
    }

    @media @desktop-wide {
        margin: 0;
        left: @layout-padding;
    }

    @media @mobile {
        width: auto;
        right: @layout-padding;
        padding: 0;
        bottom: 22px;
    }
}

.splide__arrow {
    width: 32px;
    height: 32px;
    background: none !important;
    border: 0;
    color: #FFF;
    font-size: 1rem;
    line-height: 27px;
    text-align: center;
    outline: none;
    position: relative;
    text-indent: -9999px;
    .transition(.2s);
    border-radius: 0 !important;
    opacity: 1 !important;

    @media @mobile {
        background-color: #FFF;
        color: @c-main;
    }

    &:hover {
        background-color: @c-main;
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-indent: 0;
    }

    svg {
        fill: #fff !important;
    }
}

.panel--carousel {
    position: absolute;
    top: 50px;
    left: @layout-padding;
    width: 300px;
    border: 0;

    &:before {
        left: 0;
        right: 0;
    }

    @media @mobile {
        top: auto;
        bottom: 42px;
        width: auto;
        right: @layout-padding;
    }
}

.carousel-pause__container {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;

    @media @tablet {
        right: 0;
        left: unset;
    }

    @media @mobile {
        display: none;
    }
}

#carouselPause {
    margin-bottom: 0;
    position: relative;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    min-width: 0;
    padding: 0;
    width: 3.125rem;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;

    @media @tablet {
        margin-right: 0;
    }
}

.splide__toggle__pause, .splide__toggle__play {
    // position: absolute;
    height: 30px;
    top: 0;
    color: #fff;

    path {
        fill: #fff;
    }
}

.splide__pagination {
    li {
        .splide__pagination__page.is-active {
            background: @c-secondary;
            transform: none;
            border: 1px solid @c-secondary;

            @media @mobile {
                background: #fff;
            }
        }
    }
}
