

.cookie-notice {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: #FFF;
   padding: 15px 0;
   z-index: 99;
   border-top: 1px solid #eee;
   display: none;
  
   .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media @tablet {
         flex-direction: row;
         flex-wrap: wrap;
      }
   }
   i {
      font-size: 1.2rem;
      margin-right: 10px;
   }
   p {
      margin: 0;
      font-size: 1rem;
      color: @c-body;
      a {
         font-size: inherit;
         text-decoration: underline;
         margin-left: 10px;
         position: relative;
         font-weight: 500;
         @media @tablet {
            display: block;
            margin-left: 0;
         }
         &:before {
            content: '';
            border-left: 2px solid rgba(0, 0, 0, 0.1);
            margin-right: 10px;
            @media @tablet {
               display: none;
            }
         }
      }
   }
}

.cookie-notice__text {
   flex: 1;
   @media @tablet {
      flex: 1 0 100%;
   }
   i {
      @media @mobile {
         display: none;
      }
   }
}

.cookie-notice__close {
   background-color: #eee;
   padding: 10px 20px;
   border-radius: 5px;
   cursor: pointer;
   display: inline-block;
   border: 0;
   outline: none;
   font-weight: 500;
   font-size: .9rem;
   margin: 0;
   color: #3a3a3a;
   .transition(.3s);
   @media @tablet {
      margin-top: 13px;
   }
   &:hover {
      background-color: #313d46;
      color: #FFF;
   }
}
