.panel-row {
    margin: @content-padding 0;
    position: relative;
    &--padding {
        margin: 0;
        padding: @content-padding 0;
    }
    &--grey {
        background-color: @c-grey;
    }
}

.panel-col {
    display: flex;
}

.panel {
    border: 0;
    border-radius: 0;
    margin: 0 0 1.875rem 0;
    flex: 1;
    position: relative;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    .transition(.3s);
    &--colour {
        border: 0;
    }
    &--icon {
        padding-right: 5rem;
    }
    &--image-icon {
        .panel__icon {
            top: @panel-padding + 20px;
            right: @panel-padding + 20px;
        }
    }
    &--block {
        background-color: #DDDDDD;
        padding-top: 78.57%;
        &:after {
            content: '';
            position: absolute;
            top: 0; 
            left: 0;
            width: 100%;
            height: 100%;
            background-color: fade(@c-blue, 90%);
            pointer-events: none;
            opacity: 0;
            z-index: 1;
            .transition(.3s);
        }
        &:hover {
            background-color: @c-blue;
            color: #FFF;
            &:after {
                opacity: 1;
            }
        }
        .panel__body {
            position: absolute;
            top: 0;
        }
    }
    &--has-bg {
        color: #FFF;
        // &:after {
        //     background-color: #000;
        //     opacity: .1;
        // }
    }
    &:hover {
        .panel__image-container {
            &:after {
                opacity: .9;
            }
        }
    }
}

.panel__body {
    padding: @panel-padding;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    &--block {
        display: block;
    }
    p {
        width: 100%;
    }
}

.panel__title {
    font-weight: 500;
    font-size: 1.4375rem;
    margin: 0 0 1.25rem 0;
    display: inline-block;
    position: relative;
    z-index: 2;
}

.panel__subtitle {
    margin: 0 0 0.625rem 0;
    font-weight: 500; 
}

.panel__image-container {
    margin-bottom: 1.875rem;
    width: 100%;
    max-width: 100%;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @c-main;
        opacity: 0;
        .transition(.3s);
    }
}

.panel__image {
    width: 100%;
}

.panel__links {
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
}

.panel__link {
    color: @c-main;
    font-weight: 500;
    margin-top: auto;
    display: block;
    &:after {
        content: '\f138';
        font-family: 'fontAwesome';
        font-size: 1.3rem;
        color: @c-secondary;
        margin-left: 10px;
        display: inline-block;
        transform: translateY(2px);
    }
    &--no-icon {
        &:after {
            display: none;
        }
    }
    &:empty {
        &:after {
            display: none;
        }
    }
}

.panel__list-group {
    margin: 0;
    width: 100%;
    list-style-type: none;
    padding: 0;
}

.panel__list-item {
    border: 0;
    border-bottom: 2px solid @c-secondary;
    margin:0;
    padding: 0;
    border-radius: 0 !important;
    &:first-of-type {
        .panel__list-link {
            padding-top: 0;
        }
    }
    &:last-of-type {
        border-bottom: 0;
        .panel__list-link {
            padding-bottom: 0;
        }
    }
}

.panel__list-link {
    padding: 10px 0;
    display: block;
    color: @c-body;
}

.panel__icon {
    position: absolute;
    top: @panel-padding;
    right: @panel-padding;
    font-size: 2rem !important;
    width: 60px;
    height: 60px;
    background-color: @c-secondary;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #FFF;
}
 
.panel__tags {
    margin: 15px 0 0 0;
    padding: 0;
}

.panel__tag {
    background-color: @c-main;
    color: #FFF;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: .9rem;
}

.panel--block {

}

.panel-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: .5;
    z-index: 1;
}