body.ie {
    .meet-the-team__panel {
        flex: 0 1 238px;
    }

    .arrows--three-col {
        background-repeat: repeat;
    }

    select::-ms-expand {
        display: none;
    }
    .navbar{
        flex: 0 1 auto;
    }
    picture.lazy-load {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .header__search-trigger {
        background-size: 100%;
    }
}   

// body.ie--10, body.ie--11 {
//     .scroll-slide__img-container {
//         position: relative;
//     }
// }