.events-list__container {
    background-color: #f5f5f5;
    padding: 30px;
}

.events-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.events-list__item {
    border-bottom: 2px solid rgb(207, 207, 207);
    padding: 20px 0;
    &:last-of-type {
        border: 0;
    }
}

.events-list__section-title {
    &:extend(.panel__title);
    margin-bottom: 10px;
}

.events-list__title {
    margin-bottom: 10px;
    font-size: 1.3rem;
}