.publication-info__panel {
    background-color: #eee;
    padding: 20px;
    margin: 10px 0;
    // border: solid 1px black;
}

.publication-info__text {
    margin: 0;
}

.publication-img__container {
    position: relative;
}

.publication-img {
    width: 100%;
    // margin-bottom:5px;
}

.publication-button {
    width: 100%; 
    margin: 10px 0;
    padding: 20px;
}