.sidenav__list {
    padding: 0;
    margin: 0;
}

.sidenav__item {
    list-style-type: none;
    margin-bottom: 1.25rem;
    @media @desktop {
        margin-bottom: 0.9375rem;
    }

 
}

.sidenav__link {
    color: @c-body;
    text-decoration: none;

    &--active {
        color: #286EF0;
        font-weight: 600;
    }

    &:hover {
        font-weight: 600;
    }

    &:before {
        @media @tablet {
            content: ">";
            margin-right: 3px;
            color: inherit;
        }
    }
}
.mobile-sidenav{
    display: none; 
     
@media @tablet {
     display: block;
}
    margin-bottom: 40px;
}
.mobile-subTitle{
    display: none;
    margin-bottom: 20px;
    color: @c-blue;
    font-weight: 600;
    @media @tablet{
        display: block;
    }
}