.header__search-container {
    position: relative;
    z-index: 101;
    @media @desktop {
        right: 4.375rem;
    }
}

.header__search-trigger {
    width: 17px;
    height: 22px;
    background-image: url(/img/search.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    cursor: pointer;
    margin-left: 20px;
    // position: relative;
    // top: -2px;
    @media @desktop {
        margin: 0 5px 0 0;
    }
}


.header__search-form {
    position: absolute;
    min-width: 21.875rem;
    right: -1.25rem;
    top: 3.5rem;
    display: flex;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-30px);
    box-shadow: 0 0 40px 0 rgba(0,0,0,.2);
    .transition(.3s);
    &--active { 
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
    }
    @media @desktop {
        position: fixed;
        left: 0;
        right: 0;
        top: 5.5rem;
    }
}

header:not(.header--homePage) {
    .header__search-form {
        @media @desktop-min {
            top: 3.4375rem;
        }
        @media @desktop {
            top: @header-height--small;
        }
    }
    .header__search-trigger {
        top: 2px;
        position: relative;
    }
}

.header__search-input {
    border: 1px solid #ddd;
    padding: 8px 12px;
    flex: 1;
    @media @tablet {
        padding: 15px;
    }
}

.header__search-submit {
    -webkit-appearance: none;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 8px 20px;
    background-color: @c-main;
    color: #fff;
    font-weight: 500;
    margin-left: -1px;
    font-size: 1.0625rem;
    position: relative;
    overflow: hidden;
    font-family: @font-sans-serif;
    cursor: pointer;
    .transition(.2s);
    height: 50px !important;

    &:hover {
        background-color: lighten(@c-main, 15%);
    }

    @media @tablet {
        height: auto;
    }
}
