.panel--colour {
    .panel__title {
        color: #FFF;
    }
}

.panel {
    &--main {
        background-color: @c-main;
        color: #FFF;
        .custom-blockquote__icon {
            background-color: @c-main;
        }
        .button {
            background-color: lighten(@c-main, 10%);
        }
    }
    &--secondary {
        background-color: @c-secondary;
        color: #FFF;
        &.quote-panel {
            &:before, &:after {
                display: none;
            }
        }
        .panel__title {
            border-color: #FFF;
        }
        .custom-blockquote__icon {
            background-color: @c-secondary;
        }
        .button {
            background-color: lighten(@c-secondary, 10%);
        }
        .panel__icon {
            background-color: @c-main;
        }
    }
    &--warning {
        background-color: @c-warning;
        color: #FFF;
        &.quote-panel {
            &:before, &:after {
                display: none;
            }
        }
        .panel__title {
            border-color: #FFF;
        }
        .custom-blockquote__icon {
            background-color: @c-warning;
        }
        .button {
            background-color: lighten(@c-warning, 10%);
        }
        .panel__icon {
            background-color: #FFF;
            color: @c-warning;
        }
    }
}