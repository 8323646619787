.document-list {

}

.document-list__panel {
    padding: 20px 0;
    border-bottom: 2px solid #eee;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.document-list__title {

}

.document-list__content {

}

.document-list__link {
    font-weight: 500;
    &:before {
        content: '\f08e';
        font-family: 'fontAwesome';
        color: @c-secondary;
        margin-right: 10px;
    }
}