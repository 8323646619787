.link--cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	text-indent: -999999px;
}

.text-align--left {
	text-align: left;
}

.no-padding {
	padding: 0;
}

.no-margin {
	margin: 0;
}

.no-scroll {
	overflow: hidden;
}

.margin-bottom {
	margin-bottom: 20px;
}

.margin-top {
	margin-top: 20px;
}

.spacer {
	display: block;
	margin: 80px 0;
}

.word-break {
	word-break: break-word;
}

.no-list-style {
    list-style-type:none;
}


.label--hide {
    position: absolute;
    left: -9999999px;
}

.desktop-hide {
    @media @desktop-1920 {
        display: none;
    }
    @media @tablet{
        display: block;
    }
}

.tablet-hide {
    @media @tablet {
        display: none;
    }
}

.mobile-hide {
    @media @mobile {
        display: none;
    }
}

.overflow--hidden {
    overflow: hidden;
}