.main-content {
    padding: 0 0 @content-padding 0;
    &--dark {
        background-color: #353535;
        color: #FFF;
        a {
            color: inherit;
        }
        .filter-row__filter {
            &:after {
                background-image: url(/img/dropdown-arrow--white.svg);
            }
        }
    }
    .campaignPage & {
        padding-bottom: 0;
    }
}

.main-content__header, .main-content__campaignFooter {
    padding: @content-padding 0;

    @media @mobile {
        padding: @content-padding--small 0;
    }

    &--border {
        border-bottom: 1px solid @c-dark;
        padding: 3.125rem 0;
        margin-bottom: @content-padding;

        .main-content__title {
            margin-bottom: 0;
        }

        @media @tablet {
            padding: @content-padding--small 0;
        }
    }
}

.main-content__header-content {
    @media @desktop-min {
        display: flex;
        justify-content: space-between;
    }
}

.main-content__title {
    .font(@font-size: 50, @line-height: 65, @letter-spacing: 0);
    font-weight: 500;
    margin: -0.9375rem 3.75rem 0 0;
    @media @desktop {
        margin: 0 0 1.25rem 0;
    }
    @media @mobile {
        .font-size(38);
    }
}

.main-content__page-title {
    .font(@font-size: 25, @line-height: 30, @letter-spacing: -.05);
    margin-bottom: 2.5rem;
}

.main-content__content {
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
    font-weight: 300;
    @media @mobile {
        .font-size(22);
       
    }
    p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
    a {
        color: @c-main;
    }
}

.main-content__image {
    @media @tablet-min {
        float: right;
        margin: 0 0 1.25rem 1.25rem;
    }
    @media @tablet {
        width: 100%;
        margin: 1.875rem 0;
    }
}

.block-nav {
    margin-bottom: @content-padding;
}

img,
iframe {
    display: block;
    max-width: 100%;
}

.content-img {
    width: 100%;
}

.custom-bullet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    .fa {
        position: absolute;
        left: 0;
    }
}

.custom-bullet {
    padding-left: 25px;
    margin-bottom: 15px;
}

.news-image {
    margin-bottom: 20px;
}

.video-container{
    iframe{
        margin: 0 auto;
    }
}
.video-wrapper {
    iframe {
        margin: 0 auto;
    }
    padding: 5rem 0;
}

.main-content__main {
    @media @mobile {
        margin-top: 25px;
    }
}

/*.grecaptcha-badge{
    display: none;
}*/