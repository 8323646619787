.main-banner {
    background-color: @c-main;
    color: #FFF;
    position: relative;
    display: flex;
    align-items: center;
    max-height: 100vh;
    min-height: 600px;
    /*@media @desktop-wide{
       height: 350px;
       max-height: 350px;
      min-height: 350px;
   }*/
    &--caseStudy {
        min-height: 690px;
    }

    &--homePage {
        @media @desktop-min {
            height: 600px;
        }

        @media @mobile {
            .main-banner__container {
                min-height: 660px;
            }

            .main-banner__content-container {
                margin-top: -150px;
            }
        }
    }

    &:not(&--homePage) {

        @media @desktop-wide {
            height: 450px;
            max-height: 450px;
            min-height: 450px;
        }

        /*@media @desktop {
            min-height: 450px;
        }

        @media @laptop {
            height: 300px;
            max-height: 300px;
            min-height: 300px;
        }*/



        @media @mobile {
            min-height: 250px;
            height: 300px;
        }
    }
}

.main-banner__container {
    color: #fff;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    @media @mobile {
        flex-wrap: wrap;
    }
}

.main-banner__content-container {
    // max-width: 750px;
    flex: 0 1 52.5rem;
    @media @desktop-min {
        margin-top: 4rem;
        /*margin-top: -7.6875rem;*/
        margin-right: 1.5625rem;
        // margin-right: -11px;
    }
}

.main-banner__img {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    object-fit: cover;
}

.main-banner__video {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    object-fit: cover;
}


.main-banner__title {
    .font(@font-size: 99, @line-height: 110, @letter-spacing: -1.49);
    margin: 0;
    font-weight: 300;
    @media @tablet {
        .font-size(80);
    }
    @media @mobile {
        .font-size(60);
        max-width: 202px;
    }
}

.main-banner__text {
    font-size: 1.5625rem;
    font-weight: 300;
    margin-top: 30px;
    .line-height(34, 25);
    @media @desktop-min {
        margin-left: 11px;
    }
    @media @mobile {
        display: none;
    }
    p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
    // br {
    //     @media @tablet {
    //         display: none;
    //     }
    // }
}

