.breadcrumb {
    background: none;
    padding: 0;
    margin: 0 0 1.25rem;
    list-style-type: none;
    color: lighten(@c-body, 40%);
}

.breadcrumb__item {
    margin: 0.3125rem 0.4375rem 0 0 !important;
    display: inline-block;
    font-size: .85rem;
    &:not(:first-of-type) {
        &:before {
            content: '/';
            font-weight: 400;
            margin-right: 0.4375rem;
        }
    }
}

.breadcrumb__link {
    color: lighten(@c-body, 40%);
    text-decoration: none;
    font-weight: 500;
    &:hover {
        color: @c-main;
    }
}