.row {
    width: auto;
    margin: 0 -@column-padding;
    display: flex;
    flex-wrap: wrap;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    &--reverse {
        flex-direction: row-reverse;
    }
}


.container {
    width: 100%;
    max-width: @container-width;
    margin: 0 auto;
    position: relative;
    padding: 0 @layout-padding;
    @media @mobile {
        padding: 0 2.5rem;
    }
    &--static {
        position: static;
    }
    &--center {
        max-width: @container-width--small;
    }
    &--max-width {
        max-width: 800px !important;
    }
    &--fluid {
        max-width: none;
    }
    &--half {
        max-width: @container-width / 2;
        margin: 0;
    }
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}




@cols: 12;

.generateCols(@counter) when (@counter > 0) {
    @width: round((@counter / @cols ) * 100, 4);

    .col-md-@{counter} {
        flex-basis:~"@{width}%";
        max-width:~"@{width}%";
    }

    .generateCols((@counter - 1));
}

.generateCols(@cols);

// Desktop
.generateColsDesktop(@counter) when (@counter > 0) {
    @width: round((@counter / @cols ) * 100, 4);

    .desktop-@{counter} {
        flex-basis:~"@{width}%";
        max-width:~"@{width}%";
    }

    .generateColsDesktop((@counter - 1));
}

@media @desktop {
    .generateColsDesktop(@cols);
}

// Tablet
.generateColsTablet(@counter) when (@counter > 0) {
    @width: round((@counter / @cols ) * 100, 4);

    .tablet-@{counter} {
        flex-basis:~"@{width}%";
        max-width:~"@{width}%";
    }

    .generateColsTablet((@counter - 1));
}

@media @tablet {
    .generateColsTablet(@cols);
}

// Mobile
.generateColsMobile(@counter) when (@counter > 0) {
    @width: round((@counter / @cols ) * 100, 4);

    .mobile-@{counter} {
        flex-basis:~"@{width}%";
        max-width:~"@{width}%";
    }

    .generateColsMobile((@counter - 1));
}

@media @mobile {
    .generateColsMobile(@cols);
}

// Target all cols
[class*='col-'] {
    padding: 0 @column-padding;
    position: relative;
    min-height: 1px;
    @media @mobile {
        flex-basis: 100%;
        max-width: 100%;
    }
}

[class*='col-']:not([class*='tablet-']) {
    @media @tablet {
        flex-basis: 100%;
        max-width: 100%;
    }
}


.col {
    @media @desktop {
        &:not(:last-of-type) {
            margin-bottom: 3.125rem;
        }
    }

    &--grow {
        max-width: none;
        flex-grow: 1;
    }

    &--tablet-hide {
        @media @tablet {
            display: none;
        }
    }
}

.pull-3 {
    left: -(100 / 12) * 3%;
    @media @desktop {
        left: 0;
    }
}

.pull-9 {
    left: -(100 / 12) * 9%;
    @media @desktop {
        left: 0;
    }
}

.push-3 {
    left: (100 / 12) * 3%;
    @media @desktop {
        left: 0;
    }
}

.push-7 {
    left: (100 / 12) * 7%;
    @media @desktop {
        left: 0;
    }
}

// umbraco grid hack
.fullwidth {
    margin: 30px 0;
}

[class*='col-'] {
    .container {
        padding: 0;
    }
}