.careers-panel {
    background-color: @c-grey--dark;
    color: #fff;
}

.careers-panel__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /*margin-bottom: 50px;*/
}

.careers-panel__job {
    flex: 1 1 33%;
    position: relative;
    margin-top: 50px;
    @media @desktop {
        flex: 0 1 50%;
    }
    @media @mobile {
        flex: 0 1 100%;
    }
}

.careers-panel__job-title {
    font-weight: 700;
}

.careers-panel__job-location {
    padding-left: 20px;
    position: relative;
   font-weight: 500;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        background-image: url(/img/map-icon.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 10px;
        height: 13px;
    }
}

.careers-panel__text {
   display: flex;
   justify-content: space-between;
   p {
       max-width: 800px;
   }
}
.careers-panel__form {
    text-align: center;
}
.careers-list__container{
    margin-top: 100px;
}
.careers-list__heading {
    margin-bottom: -30px;
    border-bottom: solid 2px lightgrey;
    padding-bottom: 15px;
    font-weight: 500;
}
.career-text.nested-panel__header-intro {
    p {
        font-size: 20px;
    }
}