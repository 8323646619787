.job-page__location {
    .font(@font-size: 28, @line-height: 1, @letter-spacing: 0);
    margin: 20px 0 0 0;
    display: block;
    @media @tablet {
        .font-size(20);
        margin-top: 10px;
    }
    &:before {
        content: '';
        background-image: url("/img/LocationIcon.svg");
        width: 18.25px;
        height: 24.34px;
        display: inline-block;
        margin-right: 20px;
    }
}

.job-page__details {
    margin-top: 66px;
    max-width: 820px;
}

.job-page__detail {
    &:not(:last-of-type) {
        margin-bottom: 60px;
    }
}

.job-page__subtitle {
    .font(@font-size: 22, @line-height: 25, @letter-spacing: 0);
    font-weight: 400;
    margin-bottom: 20px;
}
