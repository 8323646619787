﻿.case-study {
    position: relative;
    color: #FFF;
}

.case-study__container {
    position: relative;
    padding-top: 41.67%;
    min-height: 650px;
    max-height: 800px;
    @media @tablet {
        min-height: 500px;
        padding-left: 3.75rem;
        padding-right: 3.75rem;
    }
}

.case-study__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.case-study__title {
    font-weight: 500;
    .font-size(50);
    .letter-spacing(-0.75);
    margin-bottom: 30px;
    @media @tablet {
        .font-size(40);
        margin-bottom: 1.25rem;
        /*width: 90%;*/
    }
    // @media @desktop {
    //     font-size: 2.5rem;
    // }
    // @media @tablet {
    //     font-size: 2.3rem;
    // }
}

.case-study__content {
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 867px;
    @media @tablet {
        max-width: ~"calc(100vw - 7.5rem)";
    }
    a {
        color: inherit;
    }
    &--right {
        right: @layout-padding;
        text-align: right;
    }
}

.case-study__text {
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
    font-weight: 300;
    margin-bottom: 1.875rem;
    @media @desktop {
        font-size: 1.4rem;
        display: none;
    }
    @media @tablet {
        font-size: 1.2rem;
    }
    p {
        font-size: inherit;
        font-weight: inherit;
    }
}

.case-study__content-link {
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
}

.case-study__link {
    color: inherit;
    position: absolute;
    bottom: 60px;
    font-size: 1rem;
    z-index: 1000;

    &--right{
        right: @layout-padding;
    }
    &--top{
        top: 8%;
        bottom: auto;
    }
}

.owl-dots {
    position: absolute;
    bottom: 30px;
    left: 50%;
    // margin-left: -(@container-width / 2) + @layout-padding;
    transform: translateX(-50%);
}

.owl-dot {
    height: 14px;
    width: 14px;
    // background-color: #FFF;
    background: none;
    border: 1px solid #FFF !important;
    border-radius: 50%;
    margin-right: 10px;
    // opacity: .5;
    &.active {
        // opacity: 1;
        background-color: #FFF;
    }
    &:focus {
        outline: none;
    }
}

.case-study__slide {
    position: relative;
    background-color: @c-main;
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 75px;
        min-height: 130px;
        background-image: url(/img/CaseStudyArrowsWhite.svg);
        background-repeat: no-repeat;
        max-width: 1530px;
        width: 100%;
        z-index: 100;
        @media @desktop-wide {
            left: @layout-padding;
            right: @layout-padding;
            width: auto;
            // width: 100%;
            transform: none;
        }
        @media @mobile{
            min-height: 85px;
        }
    }
    &--bottom {
        &:after {
            bottom: 50px;
            top: auto;
            @media @tablet {
                bottom: 30px;
            }
        }
        /*.case-study__link {
            top: 75px;
        }*/ 
    }
}

.owl-prev, .owl-next {
    position: absolute; 
    top: 47%;
    background: none;
    width: 15px;
}

.owl-prev {
    left: 30px;
    @media @desktop-wide {
        left: 10px;
    }
}

.owl-next {
    right: 30px;
    @media @desktop-wide {
        right: 10px;
    }
}