.social-icons {

}

.social-icons--sidebar {
    margin-top: 30px;
}

.social-icon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: inline-flex;
    text-decoration: none;
    color: #FFF;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 0;
    position: relative;
    overflow: hidden;
    background-color: #D0D0D0;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        opacity: 0;
        .transition(.2s);
    }
    &:hover {
        color: #FFF;
        text-decoration: none;
        &:after {
            opacity: .2;
        }
    }
    &:last-of-type {
        margin-right: 0;
    }
    &[class*="linkedin"] {
        background-color: #0e76a8 ;
    }
    &[class*="twitter"] {
        background-color: #55acee;
    }
    &[class*="xing"] {
        background-color: #126567;
    }
    &[class*="facebook"] {
        background-color: #39569c;
    }
    &[class*="google"] {
        background-color: #DD4B39;
    }
    i {
        color: #FFF !important;
    }
}

