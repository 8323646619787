.toolkit__button-group {
    display: flex;
    justify-content: space-between;
    margin: 20px -10px;
    flex-wrap: wrap;
}

.toolkit__button {
    background-color: #939598;
    color: #FFF;
    flex: 1 1 16%;
    margin: 10px;
    padding: 15px;
    display: inline-flex;
    text-transform: uppercase;
    font-weight: 500;
    .transition(.3s);
    @media @desktop {
        flex: 1 1 30%;
    }
    @media @mobile {
        flex: 1 1 100%;
    }
    &:hover {
        &:not(.active) {
            background-color: lighten(#939598, 10%);
        }
    }
    &:focus, &:hover {
        color: #FFF;
        text-decoration: none;
    }
    &.active {
        background-color: @c-main;

    }
}

.toolkit__col {
    @media @mobile {
        width: 100%;
    }
}