.publications__search-box {
    background-color: #f5f5f5;
    padding: 30px;
    margin: 0 0 30px;
}

.publications__title {
    margin-bottom: 20px;
}

.publications__dropdown-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.publications__dropdown-col {
    flex: 1 1 25%;
    margin: 10px;
}

.publications__dropdown-title {
    margin-bottom: 10px; 
    font-size: 1rem;
    font-weight: 500;
}

.publications__input {
    width: 100%;
    border: 1px solid #eee;
    padding: 10px;
    margin-bottom: 10px;
    display: block;
}

.publications__button {
    margin-top: 20px;
}