header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    z-index: 101;
    position: relative;
    &.header--homePage {
        position: absolute;
        top: 2.5rem;
        right: 0;
        left: 0;
        @media @desktop {
            top: 1.875rem;
        }
        .header__search-trigger {
            @media @desktop-min {
                margin-top: 0.625rem;
            }
        }
    }
    &:not(.header--homePage) {
        background-color: @c-grey--dark;
        position: fixed;
        width: 100%;
        top: 0;
        .logo {
            width: 7.6875rem;
            height: 1.75rem;
        }
        .navbar {
            margin-top: 0;
            @media @desktop {
                top: @header-height--small;
            }
        }
        .header__container {
            align-items: center;
        }
    }
}

.logo {
    display: block;
    background-image: url(/img/Logo2.svg);
    // background-size: 100% 100%; 
    background-repeat: no-repeat;
    background-position: center;
    width: 18.5625rem;
    height: 4.1875rem;
    will-change: transform;
    margin-right: 1.875rem;
    .transition(.3s);
    // @media @tablet-min {
    //     &:hover {
    //         transform: scale(.98);
    //     }
    // }
    // @media @desktop-wide {
    //     width: 11.25rem;
    //     height: 2.625rem;
    // }
    @media @desktop {
        width: 9.375rem;
        height: 2.1875rem;
    }
}



.header__container {
    display: flex;
    justify-content: space-between;
    height: 4.0625rem;
    @media @tablet {
        border: none;
    }
}

