.quote-panel {
    // background-color: #FFF;
    // color: @c-grey--dark;
    color: inherit;
    position: relative;
    text-align: center;
}


.quote-panel__text {
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
    font-weight: 300;
    max-width: 57.1875rem;
    margin: 0 auto;
    margin-bottom: 2.5rem;
    padding-top: 6.25rem;
    @media @mobile {
        .font-size(18);
    }
    &:before {
        content: '\201C';
        color: @c-secondary;
        font-size: 12.5rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        line-height: .7;
        @media @mobile {
            font-size: 10rem;
        }
    }
    p {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
    }
}

.quote-panel__sub {
    .font(@font-size: 18px, @line-height: 22, @letter-spacing: 0);
    
}
.body--caseStudy {
    .nested-panel--testimonial {
        background-color: #F8F8F8;
        .quote-panel {
            /*background-color: #F8F8F8;*/
        }
    }
}