html {
    font-size: @base-font-size*1px;
    @media @desktop-wide {
        font-size: 14px;
    }
    @media @laptop {
        font-size: 12px;
    }
}

body {
    color: @c-body;
    padding: 0;
    margin: 0;
    font-family: @font-sans-serif;
    font-size: inherit;
}

.body--overlay {
    overflow: hidden;
    &:after {
        visibility: visible;
        opacity: 1;
    }
}

.body--nav-open {
    overflow: hidden;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // overflow-x: hidden;
    flex: 1;
    // padding-top: @header-height;
} 

body:not(.body--homePage) {
    .wrapper {
        padding-top: @header-height--small;
    }
    .main-banner {
        max-height: ~"calc(100vh - @{header-height--small})";
    }
}

main {
    flex: 1 0 auto;
    height: 100%;
    // overflow-x: hidden;
}

