// @font-face {
//     font-family: 'Shapiro';
//     font-weight: 100;
//     font-display: swap;
//     font-style: normal;
//     src: url('/fonts/Shapiro-15Fly/font.woff2') format('woff2'), url('/fonts/Shapiro-15Fly/font.woff') format('woff');
// }

@font-face {
    font-family: 'Shapiro';
    font-weight: 300;
    font-display: swap;
    font-style: normal;
    src: url('/fonts/Shapiro-25SuperFly/font.woff2') format('woff2'), url('/fonts/Shapiro-25SuperFly/font.woff') format('woff');
}

// @font-face {
//     font-family: 'Shapiro';
//     font-weight: 400;
//     font-display: swap;
//     font-style: normal;
//     src: url('/fonts/Shapiro-35Feather/font.woff2') format('woff2'), url('/fonts/Shapiro-35Feather/font.woff') format('woff');
// }

@font-face {
    font-family: 'Shapiro';
    font-weight: 500;
    font-display: swap;
    font-style: normal;
    src: url('/fonts/Shapiro-45Welter/font.woff2') format('woff2'), url('/fonts/Shapiro-45Welter/font.woff') format('woff');
}

@font-face {
    font-family: 'Shapiro';
    font-weight: 600;
    font-display: swap;
    font-style: normal;
    src: url('/fonts/Shapiro-55Middle/font.woff2') format('woff2'), url('/fonts/Shapiro-55Middle/font.woff') format('woff');
}

@font-face {
    font-family: 'Shapiro';
    font-weight: 700;
    font-display: swap;
    font-style: normal;
    src: url('/fonts/Shapiro-65LightHeavy/font.woff2') format('woff2'), url('/fonts/Shapiro-65LightHeavy/font.woff') format('woff');
}


h1,
h2,
h3,
h4,
h5 {
    font-weight: 300;
    line-height: 1;
    margin: 0;
    &:not(:first-of-type) {
        margin: 15px 0;
    }
    &:empty {
        margin: 0;
    }
}

h1 {
    font-size: 2.4rem;
    font-weight: 400;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 1.4rem;
}

p {

    // font-size: 1rem;
    // line-height: 1.5;
    .font(@font-size: 16, @line-height: 25, @letter-spacing: 0);
    margin: 0;
    &:not(:first-of-type) {
        margin: 15px 0;
    }
    &:empty {
        margin: 0;
    }
    span {
        font-size: inherit;
        line-height: inherit;
    }
}

strong {
    font-weight: 700;
}

main {
    ul {
        margin: 15px 0;
        padding: 0 0 0 20px;
        &:first-of-type {
            margin-top: 0;
        }
    }
    li {
        .font(@font-size: 16, @line-height: 25, @letter-spacing: 0);
        margin-bottom: 20px;
        padding-left: 5px;
        &:last-of-type {
            margin: 0;
        }
    }
}

.intro-text {
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
    margin-bottom: 25px;
    display: block;
    font-weight: 300;
}

a {
    text-decoration: underline;
    font-weight: 500;
    color: @c-main;
}