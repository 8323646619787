
.navbar {
    background-color: transparent;
    border-radius: 0;
    min-height: 0;
    margin: 0;
    .transition(.3s);
    display: flex;
    justify-content: space-between;
    @media @desktop-min {
        max-width: 56.25rem;
        width: 100%;
        flex: 1;
        margin-top: 7px;
        margin-left: auto;
    }
    @media @desktop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: @c-main;
        color: #FFF;
        padding: 30px 2.5rem;
        transform: translateX(100%);
        // max-height: ~"calc(100vh - @{header-height--desktop})";
        max-height: 100%;
        overflow-y: auto;
        // z-index: -1;
        width: 100%;
        max-width: 450px;
        .transition(.4s);
        &--active {
            transform: translateX(0);
            box-shadow: 0 20px 30px -10px rgba(0,0,0,.2);
        }
    }
}

.navbar__container {
    display: flex;
    padding: 0 !important;
    max-width: 800px;
    margin-right: 10px;
    
    @media @tablet{ 
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
    }
}

.navbar-nav {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    @media @desktop-min {
        display: flex;
        justify-content: space-between;
    } 
    @media @desktop {
        margin: 0;
        padding: 0;
    }

    @media @tablet{
        position: relative;
    }
}

.nav__item {
    position: relative;
    @media @desktop {
        margin-bottom: 0.9375rem;
    }
    &--active {
        .nav__link.dropdown-toggle {
            color: @c-main;
            font-weight: 500;
            &:after {
                transform: scaleX(1);
                border-color: @c-main;
            }
        }

        >.nav__link{
            border-bottom: solid 1px #fff;
        }
    }
    &--parent {
        @media @desktop-min {
            display: flex !important;
            align-items: stretch;
            &:hover {
                .nav__dropdown {
                    // transform: translateY(0) translateX(-50%);
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }
            }
        }
    }
    &--dropdown {
        border-bottom: 0.5px solid #353535;
        &:last-of-type {
            border: 0;
        }
        @media @desktop {
            border-color: #FFF;
            margin: 0;
        }
    }
    &--has-dropdown {
        @media @desktop {
            .nav__dropdown-toggle {
                display: inline-block;
            }
        }
    }
}


.nav__link {
    display: block;
    padding: 0;
    color: #fff;
    .font(@font-size: 15, @line-height: 30, @letter-spacing: .9);
    background: none;
    text-decoration: none;
    font-weight: 300;
    &:not(.nav__link--dropdown) {
        &:hover {
            background: none;
            color: darken(#fff, 5%);
        }
    }
    @media @desktop {
        padding: 0.625rem 0;
        display: inline-block;
        .font(@font-size: 33, @line-height: 40, @letter-spacing: .9);
    }
    &--dropdown {
        padding: 1rem 1.25rem;
        .font(@font-size: 15, @line-height: 20, @letter-spacing: .9);
        display: block;
        color: @c-body;
        @media @desktop {
            padding: 1.5rem;
            color: #FFF;
            .font-size(17);
        }
    }
}

.nav__dropdown {
    position: absolute;
    border: 0;
    padding: 0;
    margin: 0;
    will-change: transform;
    background-color: #FFF;
    list-style-type: none;
    .transition(.3s);
    @media @desktop-min {
        display: block;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        // transform: translateY(-0.625rem) translateX(-50%);
        margin-left: -8.125rem;
        top: 2.6875rem;
        left: 50%;
        /*padding: 0 1.25rem;*/
        box-shadow: 0 10px 20px -10px rgba(0,0,0,.5);
        width: 16.25rem;
        // overflow-y: auto;
        // max-height: 88vh;
        &:before, &:after {
            content: '';
            position: absolute;
        }
        &:before {
            top: -1.4375rem;
            right: 0;
            left: 0;
            height: 23px;
        }
        &:after {
            height: 0.9375rem;
            width: 0.9375rem;
            background-color: #FFF;
            transform: rotate(45deg);
            top: -0.3125rem;
            left: 50%;
            margin-left: -0.5rem;
            z-index: -1;
        }
    }
    @media @desktop {
        margin: 0 1.25rem;
        border-left:  1px solid #FFF;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        background: none;
        max-height: 0;
        overflow: hidden;
        .transition(.4s);
        &--active {
            max-height: 500px;
        }
    }
}

.nav__dropdown-toggle {
    display: none;
    margin-left: 0.625rem;
    &:after {
        content: '';
        background-image: url(/img/dropdown-arrow--white.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        width: 14px;
        height: 12px;
        display: block;
        .transition(.3s);
    }
    &--active {
        &:after {
            transform: scaleY(-1);
        }
    }
}



.nav-toggle {
    position: absolute;
    right: 2.5rem;
    height: 20px;
    width: 30px;
    z-index: 100;
    background: none;
    border: 0;
    cursor: pointer;
    @media @desktop-min {
        display: none;
    }
    &:focus {
        outline: none;
    }
    &--active {
        .nav-toggle__line {
            background-color: transparent !important;
            &:before {
                transform: rotate(45deg);
                top: 0;
            }
            &:after {
                transform: rotate(-45deg);
                bottom: 0;
            }
        }
    }
    &__line {
        width: 30px;
        height: 2.5px;
        background-color: #FFF;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -0.125rem;
        will-change: transform;
        pointer-events: none;
        .transition(.4s);
        &:before, &:after {
            content: '';
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: #FFF;
            left: 0;
            will-change: transform;
            .transition(.4s);
        }
        &:before {
            top: -8px;
        }
        &:after {
            bottom: -8px; 
        }
    }
}

.mobile__nav-title{
    display: none;

    @media @tablet{
        display: block;
    }

    
}
.nav-social{
    display: none;
    @media @tablet
    {
        
        display: flex; 
        margin-bottom: 20px;

    }
}