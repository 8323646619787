.nested-panel {
    padding: @content-padding 0;
    width: 100%;
    position: relative;



    &--no-padding {
        padding: 0;
    }

    &--scroll {
        padding: @content-padding--large 0;
        // @media @desktop-min {
        //     max-height: 850px;
        //     overflow: hidden;
        // }
        // &--focus {
        //     overflow-y: scroll;
        // }
    }

    &--blue {
        background-color: @c-blue;
        color: #FFF;
    }

    &--dark {
        background-color: @c-grey--dark;
        color: #fff;
    }

    &--white {
        color: #FFF;
    }


    &--sitemap {
        padding-top: 0;
    }

    &--featured {
        background-color: @c-blue;
        color: #FFF;
        /*&:before {
            content: '';
            width: 58px;
            height: 58px;
            display: block;
            background-color: inherit;
            position: absolute;
            top: -20px;
            left: 50%;
            margin-left: -29px;
            transform: rotate(45deg);
        }*/
    }

    &--leadership {
        overflow: hidden;
    }
}

.nested-panel__container--scroll {
    @media @desktop {
        padding: 0;
    }
}

.nested-panel__row {
    padding: 0 -3.125rem;
}

.nested-panel__col {
    @media @desktop-min {
        padding: 0 3.125rem;
    }
}

.nested-panel__header {
    /*border-bottom: 1px solid #FFF;
    padding-bottom: 3.125rem;
    margin-bottom: 3.125rem;*/
}

.nested-panel__title {
    .font(@font-size: 50, @line-height: 65, @letter-spacing: 0);
    margin-bottom: 3.125rem;
    &--border {
        font-weight: 300;
        font-size: 1.875rem;
        border-bottom: 1px solid #FFF;
        padding-bottom: 0.9375rem;
        margin-bottom: 2.5rem;
    }
    &--small-margin {
        margin-bottom: 1.25rem;
    }
    &--feature{
        margin-top: -8px;
    }
}

.nested-panel__subtitle {
    color: @c-secondary;
    font-weight: 700;
    margin-bottom: 0.9375rem;
}

.nested-panel__header-intro {
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
    font-weight: 300;
    p {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        margin-bottom: 1.875rem;
    }
}




//Multi image feature panel


.multi-panel__img-container{
    display: flex; 
    flex-wrap: wrap;

}
.multi-panel__img{

    margin:0 5px 5px 5px; 
}

//Testimonial slider

.testimonial-panel{
    background-color: #f8f8f8;
}

.three-panel__content{
    column-count: 2;
    column-gap: 50px;

    @media @mobile{
        column-count: unset;
    }
}
// Feature panel
.feature-panel__content {
    .font(@font-size: 20, @line-height: 34, @letter-spacing: 0);
    font-weight: 300;
    height: 100%;
    display: flex;
    flex-wrap:wrap;
    @media @mobile {
        .font-size(22);
    }

    p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
}