footer {
    background-color: @c-dark;
    color: #FFF;
    padding: @content-padding 0;
    position: relative;
    @media @mobile {
        padding: 30px 0;
    }
    p {
        .font(@font-size: 16, @line-height: 25, @letter-spacing: 0);
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.footer__row {
    @media @mobile-s {
        display: block;
    }
    &--nav {
        /*margin-top: 100px;*/
        /*@media @tablet {
            margin-top: 50px;
        }*/
    }

    &--links{
        margin-top: 20px;
    }
}


.footer__col {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media @desktop-min {
        padding: 0 15px;
    }

    @media @desktop {
        margin: 15px 0;
        flex: 1;
        max-width: none;
    }

    @media @tablet {
        flex: 0 1 50%;
        margin: 30px 0;
    }

    @media @mobile {
        flex: 0 1 50%;
        max-width: 50%;
    }

    &--logo {
        @media @desktop {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }

    &--links {
        padding-left: 0;
        padding-right: 0;
        flex: 0 1 100%;
        max-width: 100%;
    }

    span {
        display: block;
        flex: 0 1 100%;
    }

    &--address:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)):not(:nth-of-type(4)):not(:nth-of-type(5)):not(:nth-of-type(6)) {
        @media @tablet-min {
            margin-top: 40px;
        }
    }
}

.footer__logo {
    display: block;
    background-image: url(/img/Logo2.svg);
    width: 8.25rem;
    height: 3.6875rem;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.footer__link-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer__link-item {
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.footer__link {
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    &:hover {
        color: #FFF;
    }
}

.footer__title {
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.3;
    &--small {
        font-size: .8rem;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.last-updated {
    margin: 30px 0;
    display: block;
}

.last-updated__text {
    font-weight: 500;
}

//Footer nav

.footer__meganav {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer__meganav-col {
    @media @desktop {
        flex: 1 0 33.3333%;
        max-width: none;
        margin-bottom: 50px;
    }
}

.footer__meganav-subcol {
    @media @desktop {
        flex: 0 1 100%;
        max-width: 100%;
    }
}

.footer__nav-item {
    display: block;
    &:not(:last-of-type) { 
        margin-bottom: 10px;
    }
}

.footer__nav-heading {
    border-bottom: solid 2px #999;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    font-weight: 700;
}

.footer__nav-link {
    text-decoration: none;
    color: @c-body;
    font-size: .9rem;

    &--gateway{
        font-weight: 700;

    }
}

.footer-links__container {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #fff;
    padding-top: 15px;
    margin-left: 15px;
    @media @desktop-min {
        margin-top: 50px;
    }
    @media @mobile{
        display: block;
    }
}
.footer-links {
    display: flex;

}
.footer-link{
    margin-right: 30px;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    font-size: .9rem;

}

.footer-copyright{
    font-size: .8rem;
    color: #fff;

    @media @mobile{
        margin-top: 20px;
    }
}

.footer-phone{
    a{
        text-decoration: none;
        color: #fff;
    }
}

.footer-email {
    margin-top: auto;
    a {
        text-decoration: none;
        color: #fff;
    }
}

.footer-city {
    font-weight: 700;
    margin-bottom: 1.25rem;
}

.footer-address {
    margin-bottom: 1.25rem;
}


.footer-social-img{
    width: 25px;
}


.footer-social {
    display: flex;
    margin-top: 30px;
 
}

.footer-social-link {

 
}

.footer-social-link-two {
    margin-top: 5px;
    margin-left: 15px;
} 
.footer__address-row{
    display: flex;
    //justify-content: space-between; 
    flex-wrap: wrap;
}