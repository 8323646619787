﻿#colorbox {
    outline: none;
    z-index: 102;
    background: @c-main;
    background-color: rgba(40,110,240,.75);
    color: #fff;
    border-radius: 15px;
    opacity: 0;

    @media @tablet {
        max-width: 90%;
    }

    @media @mobile {
        left: 5% !important;
    }

    .umbraco-forms-navigation {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    input[type=submit] {
        width: 125px;
        height: 42px;
        padding: 0;
        background: none;
        color: #fff;
        box-shadow: none;
        outline: none;
        border: solid 2px #fff;
        font-weight: 200;
        font-size: 1rem;
        transition: all ease .3s;
        width: 160px;



        &:hover {
            cursor: pointer;
            background-color: #FFF;
            color: @c-blue;
        }
    }

    .umbraco-forms-field-wrapper {
        padding: 0;
        margin-bottom: 10px;
    }

    label {
        padding: 0;
    }

    .umbraco-forms-submitmessage {
        margin-top: 45%;
    }
}

#cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: .8 !important;
   
    background: #555050;
    z-index: 101;
   // background-color: transparent; 
   //display: none !important;
   &.initialPopupOverlay{
       display: none!important;
   }
}

#cboxClose {
    position: absolute;
    top: -15px;
    right: -15px;
   // bottom: -10px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #fff;
    background-color: @c-main;
    background-color: rgba(40,110,240,.75);
    border: 0;

    &:hover{
        cursor: pointer;

    }

   
}
.formPopupOverlay{
    .newsletter-popup__title{
        margin-bottom: 20px;
    }
    p{
        font-weight: 100;
    }
}
.initialPopupOverlay{
    #cboxClose{
        bottom: -10px;
        top: unset; 
    }
}

#popOver {
    //border: 1px solid #cfc6c6;
    text-align: center;
    left: -3000px;
    background-color: #fff;
    padding: 0.5em;
    outline: none;
    z-index: 102;
    height: 100%;
 //   background-color: @c-main;
  background-color: transparent;
}

#popOver .btn {
    display: block;
   // width: 90%;
    text-align: center;
  //  background-color: #7ac143;
    color: #fff;
   // margin: 0.5em auto;
    height: 30px;
    margin: 0 auto; 
}

#popOver .btn-close {
    width: 70%;
    background-color: #00a2e5;
}
#cboxWrapper {
    .umbraco-forms-container {
        display: block;
    }

    .umbraco-forms-navigation{
      &:before{
          display: none;
      }
    }
    @media @tablet{
        max-width: 100%;

    }
}
.hidden{ 
    
    display: none;
}
#cboxContent{
    padding: 20px;

    @media @tablet{
        max-width: 100%;
        padding: 30px;
        width: 100% !important;
    }
}
#cboxLoadedContent {
    border-radius: 15px;

    @media @tablet {
        max-width: 100%;
    }

    @media @tablet{
        overflow: unset !important;
    }
}
.newsletter-popup__title {
    font-size: 2.2rem;
    margin-bottom: 10px;
    text-align: left;
}
.newsletter-popup__text{
    p{
        text-align: left;
        
    }
    margin-bottom: 15px;
}
.newsletter-popup__btn {
    width: 125px;
    height: 42px;
    padding: 0;
    background: none;
    color: #fff;
    box-shadow: none;
    outline: none;
    border: solid 2px #fff;
    font-weight: 200;
    font-size: 1rem;
    transition: all ease .3s;
    width: 160px;
    margin-top: 10px;
    float: right;

    @media @tablet{
        float: none;
    }

    &:hover {
        cursor: pointer;
        background-color: #FFF;
        color: @c-blue;
    }
}
#initialPopup{
    p{
        font-weight: 100;
        font-size: 1.1rem;
        line-height: 1.2;
    }
    max-width: 360px;
}