.arrows {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    flex: 1;

    &--home {
        background-image: url(/img/HomeArrows.svg);
        height: 100%;
        max-height: 324px;
        margin-top: 4rem;
        /*margin-top: -96px;*/

        @media @mobile {
            position: absolute;
            bottom: 50px;
            height: 165px;
            margin: 0;
            width: ~"calc(100% - 50px)";
            // margin-top: 100px;
        }
    }

    &--carousel {
        background-image: url(/img/HomeArrows.svg);
        height: 100%;
        min-height: 20rem;

        @media @mobile {
            position: absolute;
            top: 280px;
            height: 165px;
            margin: 0;
            width: ~"calc(100% - 50px)";
            // margin-top: 100px;
        }
    }

    &--solution {
        background-image: url(/img/SolutionsContentArrows--original.svg);
        height: 58px;
    }

    &--solution-header {
        background-image: url(/img/SolutionsArrows.svg);
        width: 300px;
        height: 480px;
        /*@media @laptop {
            height: 250px;
        }*/
        @media @desktop-wide {
            height: 310px;
        }

        @media @tablet {
            height: 310px;
        }

        @media @mobile {
            height: 150px;
            width: 100px;
            flex: 0 1 160px;
        }
    }

    &--gateway {
        background-image: url(/img/SolutionsContentArrows.svg);
        // background-image: url(/img/SolutionsContentArrows--original.svg);
        // background-position: 0px 9px;
        max-width: ~"calc(75% - (@{column-padding} / 2))";
        // max-width: 75%;
        min-height: 55px;
        position: relative;
        background-position: bottom;
        background-size: 100%;
        // left: @column-padding;
        @media @desktop-min {
            transform: translateY(-25px);
        }

        @media @desktop {
            max-width: none;
            min-height: 0;
            padding-top: 7%
        }

        @media @mobile {
            background-image: url(/img/SolutionsContentArrows--original.svg);
            height: 58px;
            background-size: unset;
            background-position: unset;
            /*margin-top: 30px;*/
        }
    }

    &--news {
        background-image: url(/img/arrows-news.svg);
        flex: 0 1~"calc(66.66666% - (@{layout-padding} / 2))";
        min-height: 80px;
    }

    &--job {
        background-image: url(/img/JobArrows.svg);
        min-height: 136px;
    }

    &--case-study {
        background-image: url(/img/CaseStudyArrows.svg);
        height: 100%;
        min-height: 124px;
    }

    &--case-study-listing {
        background-image: url(/img/arrows-case-study-listing.svg);
        flex: 0 1~"calc(66.66666% - (@{layout-padding} / 2))";
        min-height: 80px;
    }

    &--team {
        background-image: url(/img/TeamArrows.svg);
        max-height: 246px;
        margin-top: -12px;
        height: 100px;
        z-index: 10;
        width: 283px;
        max-width: 283px;
        min-height: 135px;
        margin-right: 75px;

        @media @tablet {
            display: none;
        }
    }

    &--feature {
        background-image: url(/img/Arrows.svg);
        height: 135px;
        /*position: absolute;
        bottom: 0;*/
        margin-bottom: -5px;

        @media @desktop {
            position: unset;
            background-size: 100%;
            min-height: 150px;
        }

        @media @laptop {
            max-width: 250px;
        }

        @media @tablet {
            position: relative;
            margin-bottom: 30px;
            min-height: 160px;
        }

        @media @mobile {
            min-height: 160px;
            max-width: none;
        }
    }

    &--three-col {
        background-image: url(/img/arrows-threeCol.svg);
        background-repeat: space;
        height: 295px;
        width: 90%;
        margin-top: -10px;
    }
}

.arrows-blue {
    background-image: url(/img/arrow-row--blue.svg);
    background-repeat: space;
    &--one {
        height: 50px;
    }
    &--two {
        height: 100px;
    }
    &--three {
        height: 151px;
    }
    &--four {
        height: 200px;
    }
    &--five {
        height: 251px;
    }
}

.arrows-black {
    background-image: url(/img/arrow-row.svg);
    background-repeat: space;
    &--one {
        height: 50px;
    }
    &--two {
        height: 100px;
    }
    &--three {
        height: 151px;
    }
    &--four {
        height: 200px;
    }
    &--five {
        height: 251px;
    }
}