.scroll-slide__row {
    @media @desktop {
        margin: 0;
    }
}

.scroll-slide__col {
    @media @desktop {
        flex-basis: 100%;
        max-width: 100%;
    }
    &--img {
        padding-right: 6.25rem;
        @media @desktop {
            display: none;
        }
    }
    &--scroll {
        @media @desktop {
            display: flex;
            overflow-x: scroll;
            max-width: none;
            padding: 7rem 0 0 0;
        }
    }
}

.scroll-slide__subtitle {
    .font(@font-size: 22, @line-height: 26, @letter-spacing: 0);
    margin-bottom: 12.5rem;
    @media @desktop {
        display: none;
    }
    &--mobile {
        display: none;
        @media @desktop {
            display: block;
            padding-left: 3.125rem;
            margin: 0;
        }
    }
}

.scroll-slide__slide {
    .transition(.3s);
    // margin-bottom: 31.25rem;
    // margin-bottom: ~"calc(100vh - 300px)";
    margin-bottom: 300px;
    // &:not(:first-of-type) {
        opacity: .1;
    // }
    &--active {
        opacity: 1;
    }
    @media @desktop {
        opacity: 1 !important;
        flex: 1 0 25rem;
        margin: 0 0 0 3.125rem;
        &:last-of-type {
            padding-right: 3.125rem;
            flex: 1 0 28.125rem;
        }
    }
    @media @tablet{
        position: relative;
    }
}

.scroll-slide__title {
    .font(@font-size: 50, @line-height: 58, @letter-spacing: 0);
    font-weight: 400;
    margin-bottom: 1.25rem;
    @media @mobile {
        .font-size(40);
    }
}

.scroll-slide__content {
    .font(@font-size: 25, @line-height: 34, @letter-spacing: 0);
    @media @mobile {
        .font-size(20);
    }
    p {
        font-size: inherit;
        line-height: inherit;
        font-weight: 300;
    }
}

.scroll-slide__text {
    position: relative;

    @media @tablet{
        position: unset;
    }
}

.scroll-slide__img-container {
    position: relative;
    position: sticky;
    top: 60px;
    // padding-top: 102.52%
    height: ~"calc(100vh - 120px)";
}

.scroll-slide__img {
    .transition(.3s);
    // &:not(:first-of-type) {
        opacity: 0;
    // }
    &:not(&--mobile) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &--mobile {
        margin-bottom: 1.875rem;
        width: 100%;
        opacity: 1;
        @media @desktop-min {
            display: none;
        }
    }
    &--active {
        opacity: 1 !important;
    }
    
}