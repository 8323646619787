.case-study__detail-item {
    &:not(:last-of-type) {
        margin-bottom: 25px;
    }
}

.case-study__detail-heading {
    font-size: 1rem;
    margin-bottom: 0;
}

.case-study__detail {
    font-size: 1.4375rem;
    // font-family: Shapiro-35Feather;
}

.case-study__header-row {
    &:after {
        content: '';
        border-bottom: solid 2px grey;
        margin: 40px 15px;
        width: 100%;
    }
}

.main-content--case-study__text {
    p {
        font-size: 1rem;
    }
}


.case-study__info {
    position: relative;
    z-index: 10;
}

.case-study__client {}

.case-study__img-container {}

.case-study__img {}

.case-study__img-text{
    .font-size(16);
    .line-height(33,16);
    margin-top: 12px !important;
}
.case-study__img-container{
    margin-bottom: 75px;
}
.case-study__main-content {
    @media @desktop-min {
        max-width: 540px;
    }

    &--fullWidth {
        max-width: unset;
    }
}
